<template>
  <div ref="agwrapper" class="">
    <q-resize-observer @resize="onResize"></q-resize-observer>
    <div
      class="full-width row wrap justify-between items-center content-center"
    >
      <div class="text-h6 col-xs-12 col-sm-7 q-pl-xs">
        <span>{{ spreadSheetName }}</span>
      </div>
      <div class="col-xs-12 col-sm-5">
        <div
          class="full-width row wrap justify-end items-center content-center"
        >
          <div class="q-pr-xs">
            <q-btn
              no-caps
              flat
              round
              class=""
              color="info"
              dense
              icon="update"
              @click="readDataFromGoogleSheets"
            ></q-btn>
          </div>
          <div class="q-pr-xs">
            <q-btn
              no-caps
              flat
              round
              class=""
              color="green"
              dense
              icon="remove_red_eye"
              :disable="selectedRows.length < 1 || selectedRows.length > 1"
              @click="updateForms"
            ></q-btn>
          </div>
          <div class="q-pr-xs">
            <q-btn
              no-caps
              flat
              round
              class=""
              color="purple"
              dense
              icon="email"
            ></q-btn>
          </div>
          <div class="q-pr-xs">
            <q-btn
              no-caps
              flat
              round
              class=""
              color="orange"
              dense
              icon="add"
              @click="createForms"
            ></q-btn>
          </div>
          <div class="q-pr-xs">
            <q-btn
              no-caps
              flat
              round
              class=""
              color="cyan"
              dense
              icon="upload_file"
            ></q-btn>
          </div>
          <div class="q-pr-xs">
            <q-btn
              no-caps
              flat
              round
              class=""
              color="negative"
              dense
              icon="delete_forever"
              :disable="selectedRows.length < 1 || selectedRows.length > 1"
              @click="confirmDeleteDialog = true"
            ></q-btn>
          </div>
          <!-- <div class="col full-width q-pr-xs">
            <q-btn
              no-caps
              push
              class="full-width"
              color="primary"
              dense
              label="Menu"
            >
              <q-menu class="" style="">
                <q-list>
                  <q-item clickable v-close-popup class="q-ma-none q-pa-none">
                    <q-btn
                      flat
                      no-caps
                      class="full-width content-start q-ma-none q-pa-none"
                      :disable="selectedRows.length > 1"
                    >
                      <div
                        class="full-width row wrap justify-start items-center content-center q-px-md"
                      >
                        <q-icon name="remove_red_eye" size="sm"></q-icon>
                        <span class="q-pl-xs">Ver / Editar</span>
                      </div>
                    </q-btn>
                  </q-item>
                  <q-separator></q-separator>
                </q-list>
              </q-menu>
            </q-btn>
          </div> -->
          <div class="q-pr-xs">
            <q-btn
              no-caps
              flat
              round
              class=""
              color="primary"
              dense
              icon="filter_list"
              @click="floatingFilterToggle"
            ></q-btn>
          </div>
        </div>
      </div>
    </div>
    <div
      class="full-width row wrap justify-center items-center content-center q-pt-xs"
    >
      <ag-grid-vue
        class="ag-theme-material shadow-1"
        style="width: 100%; height: calc(100vh - 190px)"
        :gridOptions="gridOptions"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="rowData"
        :rowAnimation="true"
        :rowClass="rowClass"
        :rowClassRules="rowClassRules"
        @grid-ready="onGridReady"
        @selection-changed="onSelectionChanged"
      ></ag-grid-vue>
    </div>
    <q-dialog
      v-model="viewForms"
      persistent
      full-width
      full-height
      class="q-pa-none q-ma-none"
    >
      <q-card
        class="q-pa-none q-ma-none full-width full-height"
        style="background: rgba(255, 255, 255, 0.8) !important"
      >
        <div
          class="full-width row wrap justify-between items-center content-center q-ma-none q-pa-none"
        >
          <div class="col-xs-12 col-sm-4 q-pl-xs">
            <div
              class="full-width row wrap justify-center items-center content-center"
            >
              <interstellar-text id="forms" class="text-h3 q-ma-none q-pa-none">
                Forms
              </interstellar-text>
            </div>
          </div>
          <div class="col-xs-12 col-sm-8">
            <div
              class="full-width row wrap justify-end items-center content-center"
            >
              <div class="q-pr-xs">
                <q-btn
                  v-if="formMethod === 'create' || formMethod === 'update'"
                  flat
                  color="primary"
                  icon="save"
                  round
                  dense
                  @click="saveForms"
                >
                </q-btn>
              </div>
              <div class="q-pr-xs">
                <q-btn
                  v-if="formMethod === 'update'"
                  flat
                  color="purple"
                  icon="email"
                  round
                  dense
                  @click="viewForms = false"
                >
                </q-btn>
              </div>
              <div class="q-pr-xs">
                <q-btn
                  v-if="formMethod === 'create'"
                  flat
                  color="cyan"
                  icon="upload_file"
                  round
                  dense
                  @click="viewForms = false"
                >
                </q-btn>
              </div>
              <div class="q-pr-xs">
                <q-btn
                  v-if="formMethod === 'update'"
                  flat
                  color="negative"
                  icon="delete_forever"
                  round
                  dense
                  :disable="selectedRows.length < 1 || selectedRows.length > 1"
                  @click="confirmDeleteDialog = true"
                >
                </q-btn>
              </div>
              <div class="q-pr-xs">
                <q-btn
                  flat
                  color="secondary"
                  icon="close"
                  round
                  dense
                  v-close-popup
                >
                </q-btn>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-12 justify-center items-center content-center q-ma-none q-pa-none"
          style=""
        >
          <q-scroll-area
            class="q-ma-none q-pa-none full-width"
            style="height: 84vh"
          >
            <forms
              :spreadSheetName="spreadSheetName"
              :spreadSheetMetaData="spreadSheetMetaData"
              :selectedRows="selectedRows"
              :formMethod="formMethod"
              @getNewData="getNewData"
            ></forms>
          </q-scroll-area>
        </div>
      </q-card>
    </q-dialog>
    <q-dialog v-model="confirmDeleteDialog" persistent>
      <q-card style="background: rgba(255, 255, 255, 0.7) !important">
        <q-card-section class="row items-center">
          <div class="q-ml-sm text-h6">Eliminar registro</div>
        </q-card-section>
        <q-card-section class="row items-center">
          <q-icon name="delete_forever" color="negative" size="md"></q-icon>
          <div class="q-ml-sm">Esta acción es irreversible</div>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat label="Cancel" color="primary" v-close-popup></q-btn>
          <q-btn
            flat
            label="Eliminar"
            color="primary"
            v-close-popup
            @click="deleteDataFromGoogleSheets"
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import {
  defineAsyncComponent,
  ref,
  // computed,
  // watch,
  // onMounted,
  nextTick,
} from "vue";
// import { useQuasar, QSpinnerGears } from "quasar";
import { GOOGLE_DRIVE_IDS } from "@/api/gapi/gapiConstants";
import { callScriptFunction } from "@/api/gapi/gapiCallScriptFunctions";
import qHelpers from "@/helpers/qHelpers";
import {
  createMetaDataFormat,
  createColumsFormat,
  createRowsFormat,
} from "@/helpers/gapiHelpers";
import { isEmpty } from "lodash";
import { AgGridVue } from "ag-grid-vue3";
import "ag-grid-enterprise";

export default {
  name: "solicitudesseguimiento",
  components: {
    Forms: defineAsyncComponent(() => import("@/views/forms/views/Forms.vue")),
    InterstellarText: defineAsyncComponent(() =>
      import("@/components/common/InterstellarText.vue")
    ),
    AgGridVue,
  },
  props: {
    title: {
      type: String,
      default: "Tabla",
    },
  },
  setup() {
    console.log("SolicitudesSeguimiento ready");
    const {
      // $q,
      qLoadingShow,
      qLoadingHide,
      qNotifyError,
      qNotifySucces,
    } = qHelpers();
    const agwrapper = ref(null);
    const viewForms = ref(false);
    const confirmDeleteDialog = ref(false);
    const spreadSheetName = ref("");
    const spreadSheetMetaData = ref([]);
    const dataFromForms = ref({
      data: {},
      isDataValid: [false],
    });
    const formMethod = ref("create");
    const columnDefs = ref([]);
    const rowData = ref([]);
    const selectedRows = ref([]);
    const gridApi = ref({});
    const gridColumnApi = ref({});
    const defaultColDef = ref({
      suppressMenu: false,
      sortable: true,
      // filter: true,
      // floatingFilter: true,
      resizable: true,
      minWidth: 50,
      maxWidth: 500,
    });
    const rowClass = ref("bg-transparent");
    const rowClassRules = ref({
      // "price-good": "data.price <= 32000",
      // "price-warning": (params) => {
      //   let mount = params.data.price;
      //   return mount > 32000 && mount <= 35000;
      // },
      // "price-breach": "data.price >= 72000",
    });
    const gridOptions = ref({
      pagination: true,
      // rowSelection: "single",
      rowSelection: "multiple",
      localeText: {
        // for filter panel
        page: "Pagina",
        more: "Mas",
        to: "a",
        of: "de",
        next: "Siguente",
        last: "Último",
        first: "Primero",
        previous: "Anteror",
        loadingOoo: "Cargando...",
        // for set filter
        selectAll: "Seleccionar Todo",
        searchOoo: "Buscar...",
        blanks: "En blanco",
        // for number filter and text filter
        filterOoo: "Filtrar",
        applyFilter: "Aplicar Filtro...",
        equals: "Igual",
        notEqual: "No Igual",
        // for number filter
        lessThan: "Menos que",
        greaterThan: "Mayor que",
        lessThanOrEqual: "Menos o igual que",
        greaterThanOrEqual: "Mayor o igual que",
        inRange: "En rango de",
        // for text filter
        contains: "Contiene",
        notContains: "No contiene",
        startsWith: "Empieza con",
        endsWith: "Termina con",
        // filter conditions
        andCondition: "Y",
        orCondition: "O",
        // the header of the default group column
        group: "Grupo",
        // tool panel
        columns: "Columnas",
        filters: "Filtros",
        valueColumns: "Valos de las Columnas",
        pivotMode: "Modo Pivote",
        groups: "Grupos",
        values: "Valores",
        pivots: "Pivotes",
        toolPanelButton: "BotonDelPanelDeHerramientas",
        // other
        noRowsToShow: "No hay registros para mostrar",
        // enterprise menu
        pinColumn: "Columna Pin",
        valueAggregation: "Agregar valor",
        autosizeThiscolumn: "Autoajustar esta columna",
        autosizeAllColumns: "Ajustar todas las columnas",
        groupBy: "agrupar",
        ungroupBy: "desagrupar",
        resetColumns: "Reiniciar Columnas",
        expandAll: "Expandir todo",
        collapseAll: "Colapsar todo",
        toolPanel: "Panel de Herramientas",
        export: "Exportar",
        csvExport: "Exportar a CSV",
        excelExport: "Exportar a Excel (.xlsx)",
        excelXmlExport: "Exportar a Excel (.xml)",
        // enterprise menu pinning
        pinLeft: "Pin Izquierdo",
        pinRight: "Pin Derecho",
        // enterprise menu aggregation and status bar
        sum: "Suman",
        min: "Minimo",
        max: "Maximo",
        none: "nada",
        count: "contar",
        average: "promedio",
        // standard menu
        copy: "Copiar",
        copyWithHeaders: "Copiar con cabeceras",
        paste: "Pegar",
      },
      onCellDoubleClicked: updateForms,
    });
    function onGridReady(params) {
      // console.log(params);
      gridApi.value = params.api;
      gridColumnApi.value = params.columnApi;
      onResize();
    }
    function onSelectionChanged() {
      const selectedNodes = gridApi.value.getSelectedNodes();
      selectedRows.value = selectedNodes.map((node) => node.data);
    }
    async function onResize() {
      // console.log("onResize");
      if (!isEmpty(gridColumnApi.value)) {
        let allColumnIds = [];
        let allColumnWidth = [];
        let skipHeader = false;
        let agwrapperWidth = JSON.parse(
          JSON.stringify(agwrapper.value?.offsetWidth)
        );
        await nextTick();
        // gridColumnApi.value.getColumnState().forEach(function (column) {
        //   console.log(column);
        //   allColumnIds.push(column.colId);
        // });
        gridColumnApi.value.getAllColumns().forEach(function (column) {
          allColumnIds.push(column.colId);
          allColumnWidth.push(column.actualWidth);
        });
        let sumAllColumnWidth = allColumnWidth.reduce((total, num) => {
          let sum = total + num;
          return sum;
        }, 0);
        if (sumAllColumnWidth < agwrapperWidth) {
          gridApi.value.sizeColumnsToFit();
        } else {
          gridColumnApi.value.autoSizeColumns(allColumnIds, skipHeader);
        }
      }
    }
    function floatingFilterToggle() {
      let newColumnDefs = JSON.parse(JSON.stringify(columnDefs.value));
      newColumnDefs.forEach((colDef) => {
        colDef.floatingFilter = !colDef.floatingFilter;
      });
      // gridApi.value.setColumnDefs(newColumnDefs);
      columnDefs.value = JSON.parse(JSON.stringify(newColumnDefs));
      // gridApi.value.refreshHeader();
    }
    async function execCallScriptFunction(gapiFunction, gapiParams) {
      const response = await callScriptFunction(gapiFunction, gapiParams);
      if (
        response === "Error calling API" ||
        response === "Script error message"
      ) {
        qNotifyError(response);
        qLoadingHide();
        return;
      } else {
        // console.log("qLoadingHide();");
        qLoadingHide();
        return response;
      }
    }
    async function createDataToGoogleSheets() {
      // console.log(JSON.stringify(spreadSheetMetaData.value, null, "\t"));
      if (dataFromForms.value.isDataValid.indexOf(false) > -1) {
        qNotifyError("Completa los campos obligatorios");
        return;
      }
      qLoadingShow("Creando datos en Google Sheets");
      let dataToRecord = [];
      // console.log(JSON.stringify(spreadSheetMetaData.value, null, "\t"));
      // console.log(JSON.stringify(dataFromForms.value, null, "\t"));
      spreadSheetMetaData.value.forEach((item) => {
        let dataName = item["Name"];
        let itemType = item["ItemType"];
        // let itemOptions = item["ItemOptions"]
        //   ? item["ItemOptions"].split("|")
        //   : null;
        // console.log(itemOptions);
        if (
          itemType === "CHECKBOX" ||
          itemType === "LIST" ||
          itemType === "LIST_MULTIPLE_SELECT"
        ) {
          let otherIndex = null;
          if (
            dataFromForms.value.data[dataName] &&
            dataFromForms.value.data[dataName].indexOf("Otros:") > -1
          ) {
            if (Array.isArray(dataFromForms.value.data[dataName])) {
              otherIndex = dataFromForms.value.data[dataName].indexOf("Otros:");
              dataFromForms.value.data[dataName][otherIndex] =
                dataFromForms.value.dataOther[dataName];
              dataToRecord.push(dataFromForms.value.data[dataName].join("|"));
            } else {
              dataFromForms.value.data[dataName] =
                dataFromForms.value.dataOther[dataName];
              dataToRecord.push(dataFromForms.value.data[dataName]);
            }
          } else if (
            dataFromForms.value.data[dataName] &&
            Array.isArray(dataFromForms.value.data[dataName])
          ) {
            dataToRecord.push(dataFromForms.value.data[dataName].join("|"));
          } else if (dataFromForms.value.data[dataName]) {
            dataToRecord.push(dataFromForms.value.data[dataName]);
          } else {
            dataToRecord.push("");
          }
        } else if (itemType === "TIMESTAMP") {
          window.moment.locale("es-mx");
          if (item["KeyType"] === "lastUpdateKey") {
            dataToRecord.push(
              window.moment().utc().format("YYYY-MM-DD HH:mm:ss.SSS")
            );
          } else {
            dataToRecord.push(
              window
                .moment(
                  dataFromForms.value.data[dataName],
                  "YYYY-MM-DD HH:mm:ss.SSS"
                )
                .utc()
                .format("YYYY-MM-DD HH:mm:ss.SSS")
            );
          }
        } else {
          dataToRecord.push(dataFromForms.value.data[dataName]);
        }
      });

      // console.log(JSON.stringify(dataToRecord, null));
      let response = {
        info: null,
      };
      while (!response.info) {
        response = await execCallScriptFunction("createDataToGoogleSheets", [
          GOOGLE_DRIVE_IDS.GOOGLE_SHEET_CANALIZADAS,
          dataToRecord,
        ]);
      }
      if (response.info === "Exist") {
        qNotifyError("La Id ya existe");
      } else {
        viewForms.value = false;
        gridApi.value.deselectAll();
        selectedRows.value = [];
        qNotifySucces(response.info);
        readDataFromGoogleSheets();
      }
    }
    readDataFromGoogleSheets();
    async function readDataFromGoogleSheets() {
      qLoadingShow("Leyendo datos de Google Sheets");
      const response = await execCallScriptFunction(
        "readDataFromGoogleSheets",
        [GOOGLE_DRIVE_IDS.GOOGLE_SHEET_CANALIZADAS]
      );
      // console.log(response.data.dataSheet);
      loadData(response.data);
      qNotifySucces(response.info);
    }
    function loadData(data) {
      let dataSheet = JSON.parse(data.dataSheet);
      let metaData = JSON.parse(data.metaData);
      spreadSheetName.value = data.spreadSheetName;
      spreadSheetMetaData.value = createMetaDataFormat(metaData.slice(0));
      columnDefs.value = createColumsFormat(
        dataSheet[0],
        spreadSheetMetaData.value
      );
      rowData.value = createRowsFormat(dataSheet.slice(0));
      // console.log(JSON.stringify(spreadSheetMetaData.value, null, "\t"));
      // console.log(JSON.stringify(columnDefs.value, null, "\t"));
      // console.log(JSON.stringify(rowData.value, null, "\t"));
    }
    async function updateDataFromGoogleSheets() {
      if (dataFromForms.value.isDataValid.indexOf(false) > -1) {
        qNotifyError("Completa los campos obligatorios");
        return;
      }
      // console.log(JSON.stringify(selectedRows.value[0], null));
      // console.log(JSON.stringify(dataFromForms.value.data, null));
      // let itemNames = Object.keys(dataFromForms.value.data);
      // for (let item in itemNames) {
      //   if (
      //     dataFromForms.value.data[itemNames[item]] !==
      //     selectedRows.value[0][itemNames[item]]
      //   ) {
      //     console.log(itemNames[item]);
      //     break;
      //   } else {
      //     qNotifyError("No hay cambios que guardar");
      //     return;
      //   }
      // }

      let dataToRecord = [];
      spreadSheetMetaData.value.forEach((item) => {
        let dataName = item["Name"];
        let itemType = item["ItemType"];
        // let itemOptions = item["ItemOptions"]
        //   ? item["ItemOptions"].split("|")
        //   : null;
        // console.log(itemOptions);
        if (
          itemType === "CHECKBOX" ||
          itemType === "LIST" ||
          itemType === "LIST_MULTIPLE_SELECT"
        ) {
          let otherIndex = null;
          if (
            dataFromForms.value.data[dataName] &&
            dataFromForms.value.data[dataName].indexOf("Otros:") > -1
          ) {
            if (Array.isArray(dataFromForms.value.data[dataName])) {
              otherIndex = dataFromForms.value.data[dataName].indexOf("Otros:");
              dataFromForms.value.data[dataName][otherIndex] =
                dataFromForms.value.dataOther[dataName];
              dataToRecord.push(dataFromForms.value.data[dataName].join("|"));
            } else {
              dataFromForms.value.data[dataName] =
                dataFromForms.value.dataOther[dataName];
              dataToRecord.push(dataFromForms.value.data[dataName]);
            }
          } else if (
            dataFromForms.value.data[dataName] &&
            Array.isArray(dataFromForms.value.data[dataName])
          ) {
            dataToRecord.push(dataFromForms.value.data[dataName].join("|"));
          } else if (dataFromForms.value.data[dataName]) {
            dataToRecord.push(dataFromForms.value.data[dataName]);
          } else {
            dataToRecord.push("");
          }
          // console.log(JSON.stringify(dataFromForms.value.data[dataName]));
          // console.log(JSON.stringify(dataToRecord));
        } else if (itemType === "TIMESTAMP") {
          window.moment.locale("es-mx");
          if (item["KeyType"] === "lastUpdateKey") {
            dataToRecord.push(
              window.moment().utc().format("YYYY-MM-DD HH:mm:ss.SSS")
            );
          } else {
            dataToRecord.push(
              window
                .moment(
                  dataFromForms.value.data[dataName],
                  "YYYY-MM-DD HH:mm:ss.SSS"
                )
                .utc()
                .format("YYYY-MM-DD HH:mm:ss.SSS")
            );
          }
        } else {
          dataToRecord.push(dataFromForms.value.data[dataName]);
        }
      });
      let dataToDelete = JSON.parse(
        JSON.stringify(Object.values(selectedRows.value[0]))
      );
      // console.log(JSON.stringify(dataToRecord, null));
      // console.log(JSON.stringify(dataToDelete, null));
      for (let i = 0; i < dataToRecord.length - 1; i++) {
        // console.log(dataToRecord[i] === dataToDelete[i]);
        if (dataToRecord[i] !== dataToDelete[i]) {
          break;
        }
        if (i === dataToRecord.length - 2) {
          qNotifyError("No hay cambios que guardar");
          return;
        }
      }
      qLoadingShow("Actualizando datos en Google Sheets");
      let response = {
        info: null,
      };
      // while (!response.info) {
      response = await execCallScriptFunction("updateDataFromGoogleSheets", [
        GOOGLE_DRIVE_IDS.GOOGLE_SHEET_CANALIZADAS,
        { dataToRecord: dataToRecord, dataToDelete: dataToDelete },
      ]);
      // }
      if (response.info === "Exist") {
        qNotifyError("La Id ya existe");
      } else {
        viewForms.value = false;
        gridApi.value.deselectAll();
        selectedRows.value = [];
        qNotifySucces(response.info);
        readDataFromGoogleSheets();
      }
    }
    async function deleteDataFromGoogleSheets() {
      qLoadingShow("Eliminando datos de Google Sheets");
      const selectedNodes = gridApi.value.getSelectedNodes();
      if (selectedRows.value.length > 0) {
        selectedRows.value = selectedNodes.map((node) => node.data);
        let dataToDelete = Object.values(selectedRows.value[0]);
        // console.log(JSON.stringify(dataToDelete));
        let response = await execCallScriptFunction(
          "deleteDataFromGoogleSheets",
          [GOOGLE_DRIVE_IDS.GOOGLE_SHEET_CANALIZADAS, dataToDelete]
        );
        // console.log(JSON.stringify(response, null, "\t"));
        viewForms.value = false;
        gridApi.value.deselectAll();
        selectedRows.value = [];
        qNotifySucces(response.info);
        readDataFromGoogleSheets();
      }
    }
    function createForms() {
      formMethod.value = "create";
      gridApi.value.deselectAll();
      selectedRows.value = [];
      viewForms.value = true;
    }
    function updateForms() {
      formMethod.value = "update";
      // console.log(JSON.stringify(spreadSheetMetaData.value, null, "\t"));
      const selectedNodes = gridApi.value.getSelectedNodes();
      if (selectedRows.value.length > 0) {
        selectedRows.value = selectedNodes.map((node) => node.data);
      }
      viewForms.value = true;
      // console.log(JSON.stringify(selectedRows.value));
    }
    function saveForms() {
      if (formMethod.value === "update") {
        updateDataFromGoogleSheets();
      } else if (formMethod.value === "create") {
        createDataToGoogleSheets();
      }
    }
    function testBtn() {
      console.log("testBtn");
      // console.log(JSON.stringify(spreadSheetMetaData.value, null, "\t"));
    }
    function getNewData(newData) {
      dataFromForms.value = newData;
      // console.log(
      //   "dataFromForms.value: " +
      //     JSON.stringify(dataFromForms.value, null, "\t")
      // );
    }
    // watch(
    //   () => spreadSheetMetaData,
    //   (spreadSheetMetaData) => {
    //     console.log("watch: " + JSON.stringify(spreadSheetMetaData.value));
    //     // return metaData;
    //   },
    //   { deep: true }
    // );
    return {
      agwrapper,
      viewForms,
      formMethod,
      confirmDeleteDialog,
      spreadSheetName,
      spreadSheetMetaData,
      gridOptions,
      onGridReady,
      columnDefs,
      rowData,
      onSelectionChanged,
      selectedRows,
      defaultColDef,
      floatingFilterToggle,
      rowClass,
      rowClassRules,
      onResize,
      testBtn,
      getNewData,
      createDataToGoogleSheets,
      readDataFromGoogleSheets,
      updateDataFromGoogleSheets,
      deleteDataFromGoogleSheets,
      createForms,
      updateForms,
      saveForms,
    };
  },
};
</script>

<style lang="scss">
.bg-transparent {
  // background: rgba(255, 255, 255, 0.4) !important;
  cursor: pointer;
}

.q-card .q-dialog-plugin {
  background: rgba(255, 255, 255, 0.9) !important;
}

.price-good {
  background-color: rgba(255, 255, 255, 0.3) !important;
}
.price-warning {
  background-color: rgba(251, 255, 6, 0.3) !important;
}

.price-breach {
  background-color: rgba(211, 6, 6, 0.3) !important;
}

.ag-theme-material {
  /* use theme parameters where possible */
  // --ag-foreground-color: deeppink;
  // Colour of text and icons in the header
  --ag-header-foreground-color: rgba(0, 0, 0, 1) !important;
  // Background colour of the grid
  --ag-background-color: rgba(255, 255, 255, 0.7) !important;
  // Background colour for all headers, including the grid header, panels etc
  --ag-header-background-color: null;
  // Background for areas of the interface that contain UI controls, like tool panels and the chart settings menu
  --ag-control-panel-background-color: null;
  // Background color when hovering over rows in the grid and in dropdown menus, or null for no rollover effect (note - if you want a rollover on one but not the other, set to null and use CSS to achieve the rollover)
  --ag-row-hover-color: rgba(0, 27, 88, 0.7) !important;
  // Color to draw around selected cell ranges
  --ag-range-selection-border-color: rgba(0, 27, 88, 0.7) !important;
  // Suppress styling of checkbox/radio/range input elements. If you want to style these yourself, set this to true. If you only want to disable styling for some kinds of input, you can set this to true and e.g. @include ag-native-inputs((checkbox: false)) which will emit styles for all kinds of input except checkboxes.
  --ag-suppress-native-widget-styling: true;
  --ag-checkbox-background-color: null;
  --ag-checkbox-border-radius: ag-derived(border-radius);
  --ag-checkbox-checked-color: rgba(0, 27, 88, 0.7) !important;
  --ag-checkbox-unchecked-color: rgba(0, 27, 88, 0.7) !important;
  --ag-checkbox-indeterminate-color: ag-derived(checkbox-unchecked-color);

  // --ag-toggle-button-off-border-color: rgba(255, 255, 255, 0.7) !important;
  // --ag-toggle-button-off-background-color: rgba(255, 255, 255, 0.7) !important;
  // --ag-toggle-button-on-border-color: rgba(255, 255, 255, 0.7) !important;
  // --ag-toggle-button-on-background-color: rgba(255, 255, 255, 0.7) !important;
  // --ag-toggle-button-switch-background-color: rgba(255, 255, 255, 0.7) !important;
  // --ag-toggle-button-switch-border-color: rgba(255, 255, 255, 0.7) !important;
  // --ag-toggle-button-border-width: 1px;
  // --ag-toggle-button-height: 200px;
  // --ag-toggle-button-width: ag-derived(toggle-button-height, $times: 2);
}
.ag-theme-material .ag-header-cell {
  background-color: rgba(0, 27, 88, 0.7) !important;
  color: #fff;
  font-weight: bold;
  height: 100% !important;
  border-left: 1px solid rgba(255, 255, 255, 0.5) !important;
}
.ag-theme-material .ag-cell {
  border-left: 1px solid rgba(200, 200, 200, 0.5) !important;
  // border-right: 0.1px solid rgba(0, 0, 0, 0.5) !important;
}
.ag-theme-material .ag-header-icon {
  color: var(
    --ag-secondary-foreground-color,
    rgba(255, 255, 255, 0.99)
  ) !important;
}
.ag-theme-material
  .ag-header-cell-menu-button:not(.ag-header-menu-always-show) {
  transition: opacity 0.2s !important;
  opacity: 0.99 !important;
}
.q-item:hover {
  background-color: rgba(0, 27, 88, 0.7) !important;
  color: white !important;
}
.ag-theme-material input[class^="ag-"]:not([type]),
.ag-theme-material input[class^="ag-"][type="text"],
.ag-theme-material input[class^="ag-"][type="number"],
.ag-theme-material input[class^="ag-"][type="tel"],
.ag-theme-material input[class^="ag-"][type="date"],
.ag-theme-material input[class^="ag-"][type="datetime-local"],
.ag-theme-material textarea[class^="ag-"] {
  color: rgba(255, 255, 255, 0.9) !important;
}
.ag-theme-material input[class^="ag-"]:not([type]),
.ag-theme-material input[class^="ag-"][type="text"],
.ag-theme-material input[class^="ag-"][type="number"],
.ag-theme-material input[class^="ag-"][type="tel"],
.ag-theme-material input[class^="ag-"][type="date"],
.ag-theme-material input[class^="ag-"][type="datetime-local"],
.ag-theme-material textarea[class^="ag-"] {
  background: rgba(0, 27, 88, 0.5) !important;
  margin: 0;
  padding: 0 10px;
  text-align: center;
}
</style>
